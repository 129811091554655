import React from 'react';
import styled from '@emotion/styled';
import { Container } from 'react-grid-system';
import Twitter from '../../assets/Twitter';
import Github from '../../assets/Github';
import Anchor from '../Link';
import LogoInverted from '../../assets/LogoInverted';
import Logo from '../../assets/Logo';
import ThemeToggle from '../ThemeToggle/inderx';
import { useTheme } from '../../context/ThemeContext';
import LinkedIn from '../../assets/LinkedIn';

export function Navbar() {
  const themeState = useTheme();
  return (
    <StyledNavbar>
      <Container>
        <Anchor to="/">{themeState.dark ? <LogoInverted /> : <Logo />}</Anchor>
      </Container>
      <Container>
        <ul className="flex items-center">
          {/* <li className="mr-6">
            <Anchor to="/about">About</Anchor>
          </li> */}
          <li className="mr-6">
            <a href="https://github.com/FLoppix" target="_blank">
              <Github />
            </a>
          </li>
          <li className="mr-6">
            <a href="https://twitter.com/feboenke" target="_blank">
              <Twitter />
            </a>
          </li>
          <li className="mr-6">
            <a
              href="https://www.linkedin.com/in/felix-boenke-373474108/"
              target="_blank"
            >
              <LinkedIn />
            </a>
          </li>
          <li>
            <ThemeToggle />
          </li>
        </ul>
      </Container>
    </StyledNavbar>
  );
}

export const StyledNavbar = styled('div')`
  height: 80px;
  display: flex;
  width: 100%;
  background: ${(props: any) => props.theme.background}
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 6px 31px -2px rgba(0, 0, 0, 0.1);
`;
