import { useTheme } from '../../context/ThemeContext';
import { SunIcon, NightIcon } from './assets';
import styled from '@emotion/styled';

export function ThemeToggle() {
  const themeState = useTheme();
  const ThemeIcon = themeState.dark ? (
    <SunIcon color="white" />
  ) : (
    <NightIcon color="black" />
  );
  return (
    <ThemeWrapper onClick={() => themeState.toggle()}>{ThemeIcon}</ThemeWrapper>
  );
}

const ThemeWrapper = styled('span')`
  width: 24px;
  height: 24px;
  :hover {
    cursor: pointer;
  }
`;
