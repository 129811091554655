import React from 'react';
import { motion } from 'framer-motion';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTheme } from '../../context/ThemeContext';
import Navbar from '../Navbar';
import { themeLight, themeDark } from '../../styles/theme';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const container = {
  light: {
    background: themeLight.background
  },

  dark: {
    background: themeDark.background
  }
};

export function Layout({ children }) {
  const themeState = useTheme();

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Fira+Code:300,400,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper
        variants={container}
        animate={themeState.dark ? 'dark' : 'light'}
      >
        <Global
          styles={css`
            body {
              font-family: 'Fira Code', serif;
            }
          `}
        />
        <Navbar />
        {children}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(motion.div)`
  width: 100%;
`;
