import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 300 244.187"
    >
      <path
        fill="#1da1f2"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M633.898 812.045c112.46 0 173.956-93.168 173.956-173.956 0-2.647-.053-5.281-.172-7.904a124.323 124.323 0 0030.498-31.66c-10.955 4.87-22.744 8.148-35.111 9.626 12.623-7.57 22.314-19.543 26.886-33.817a122.61 122.61 0 01-38.824 14.84c-11.157-11.884-27.041-19.316-44.63-19.316-33.763 0-61.143 27.38-61.143 61.132 0 4.798.536 9.465 1.585 13.94-50.815-2.556-95.874-26.885-126.025-63.88a60.978 60.978 0 00-8.279 30.73c0 21.213 10.794 39.94 27.208 50.894a60.69 60.69 0 01-27.69-7.647c-.01.257-.01.507-.01.78 0 29.61 21.075 54.333 49.052 59.935a61.22 61.22 0 01-16.122 2.152c-3.934 0-7.766-.387-11.491-1.103 7.783 24.293 30.354 41.97 57.115 42.466-20.926 16.402-47.287 26.17-75.937 26.17-4.93 0-9.799-.28-14.585-.846 27.06 17.344 59.19 27.464 93.722 27.464"
        transform="translate(-539.18 -568.858)"
      ></path>
    </svg>
  );
}

export default Twitter;
