import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

interface Props {
  children: React.ReactNode;
  to: string;
}

export function Anchor({ children, to }: Props) {
  return <StyledLink to={to}>{children}</StyledLink>;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
